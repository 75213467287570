<template>
  <div class="article-list">
    <TopPage :goBack="'/AwardList'">
      <van-tabs type="card" v-model="active" @change="onClick" sticky background="#eeeeeee0" color="#fff" title-active-color="#65d4d0" title-inactive-color="#989595" line-height="0" line-width="0" :border="false"  :swipeable="true">
        <van-tab :title="QXC"></van-tab>
        <van-tab :title="PLW"></van-tab>
      </van-tabs>
    </TopPage>
    <div v-if="active === 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        error-text="加载失败，请重新加载"
        @load="getArticleList"
        :offset="100"
      >
        <van-cell style="border-bottom: 1px solid #ebedf0;" v-for="item in allArticleList" :key="item.id" @click="toDetail(item)">
          <span>{{ item.leftTittle }}</span>
          <span v-if="item.middleTittle" style="color: red;">{{ item.middleTittle }}</span>
          <span>{{ item.rightTittle }}</span>
        </van-cell>
      </van-list>
    </div>
    <div v-else>
      <van-list
        v-model="PLWloading"
        :finished="PLWfinished"
        finished-text="没有更多了"
        error-text="加载失败，请重新加载"
         @load="getPLWArticleList"
        :offset="100"
      >
        <van-cell style="border-bottom: 1px solid #ebedf0;" v-for="item in PLWAllArticleList" :key="item.id" @click="toDetail(item)">
          <span>{{ item.leftTittle }}</span>
          <span v-if="item.middleTittle" style="color: red;">{{ item.middleTittle }}</span>
          <span>{{ item.rightTittle }}</span>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import TopPage from './TopPage.vue';
import { articleList } from '../api/index.js'
import { textSplit } from '../utils/strSplit.js'
export default {
  name: 'ArticleList',
  components: {
    TopPage
  },
  data() {
    return {
      QXC: '七星彩',
      PLW: '排列五',
      active: 0,
      list: [],
      loading: false,
      PLWloading: false,
      PLWfinished: false,
      finished: false,
      pageObj: {
        page: 1,
        size: 15
      },
      PLWpageObj: {
        page: 1,
        size: 15
      },
      articleList: [],
      allArticleList: [],
      PLWArticleList: [],
      PLWAllArticleList: [],
      ticket_type: ''
    }
  },
  created() {
    const { name } = this.$route.query
	const { ticket_type } = this.$route.query
    if (name === this.PLW || ticket_type === 'plw') {
      this.active = 1
      this.ticket_type = 'plw'
      // this.getPLWArticleList()
    } else {
      this.active = 0
      this.ticket_type = 'qxc'
      // this.getArticleList()
    }

  },
  methods: {
    textSplit,
    toDetail(item) {
      this.$router.push({
        path: '/AwardDetail',
        query: {
          ...item
        }
      })
    },
    async getArticleList() {
      let params = {
        ...this.pageObj,
        ticket_type: 'qxc'
      }
      const res = await articleList(params)
      if (res.code === 1 && res.data.list && res.data.list.length > 0) {
         this.articleList = res.data.list.map(item => {
          return {
            ...item,
            title: this.textSplit(item.title),
            leftTittle: this.textSplit(item.title, 'left'),
            middleTittle: this.textSplit(item.title, 'middle'),
            rightTittle: this.textSplit(item.title, 'right')
          }
         })
         if (this.pageObj.page === 1) {
          this.allArticleList = this.articleList
         }else {
          this.allArticleList = [...this.allArticleList, ...this.articleList]
         }
         if (this.allArticleList.length >= res.data.total) {
          this.finished = true;
          this.$toast.success(res.message || '已加载全部数据')
          }else {
            this.pageObj.page++
          }
          this.loading = false
      } else {
        this.allArticleList = []
        this.loading = false
        this.$toast.fail(res.message || '获取数据失败')
      }
    },
    async getPLWArticleList() {
      let params = {
        ...this.PLWpageObj,
        ticket_type: 'plw'
      }
      const res = await articleList(params)
      if (res.code === 1 && res.data.list && res.data.list.length > 0) {
         this.PLWArticleList = res.data.list.map(item => {
          return {
            ...item,
            title: this.textSplit(item.title),
            leftTittle: this.textSplit(item.title, 'left'),
            middleTittle: this.textSplit(item.title, 'middle'),
            rightTittle: this.textSplit(item.title, 'right')
          }
         })
         if (this.PLWpageObj.page === 1) {
          this.PLWAllArticleList = this.PLWArticleList
         }else {
          this.PLWAllArticleList = [...this.PLWAllArticleList, ...this.PLWArticleList]
         }
         if (this.PLWAllArticleList.length >= res.data.total) {
          this.PLWfinished = true;
          this.$toast.fail(res.message || '已加载全部数据')
          }else {
            this.PLWpageObj.page++
          }
          this.PLWloading = false
      } else {
        this.PLWAllArticleList = []
        this.PLWloading = false
        this.$toast.fail(res.message || '获取数据失败')
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    onClick (name, title) {
      if (title === this.PLW) {
      this.active = 1
      this.ticket_type = 'plw'
      this.getPLWArticleList()
      }else {
        this.active = 0
        this.ticket_type = 'qxc'
        this.getArticleList()
      }
    }
  }
}
</script>

<style lang="less">
  .article-list {
    margin-top: 40px;
    v-deep.van-tab__text--ellipsis {
      font-weight: 600 !important;
    }
    van-tabs {
      @tabs-bottom-bar-width: 80px;
    }
    .van-tab__text--ellipsis {
      font-weight: bold;
    }
    .van-tabs__wrap {
      width: 220px;
      font-weight: bold;
    }
  }
  
</style>