export const winningResult = {
  "code": 1,
  "message": "success",
  "data": [{
      "id": 6,
      "ticket_type": "plw",
      "name": "排列五",
      "issue_title": "24318期 2024-11-27 周三",
      "issue_num": "24318",
      "red_number": "[2, 4, 3, 9]",
      "blue_number": "[8]",
      "remark": null,
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }, {
      "id": 5,
      "ticket_type": "qxc",
      "name": "七星彩",
      "issue_title": "24136期 2024-11-26 周二",
      "issue_num": "24136",
      "red_number": "[7, 7, 7, 8]",
      "blue_number": "[1, 6, 1]",
      "remark": null,
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }, {
      "id": 4,
      "ticket_type": "pls",
      "name": "排列三",
      "issue_title": "24318期 2024-11-27 周三",
      "issue_num": "24318",
      "red_number": "[2, 4, 3]",
      "blue_number": "[]",
      "remark": null,
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }, {
      "id": 3,
      "ticket_type": "dlt",
      "name": "大乐透",
      "issue_title": "24138期 2024-11-27 周三",
      "issue_num": "24138",
      "red_number": "[05, 17, 20, 28, 34]",
      "blue_number": "[04, 09]",
      "remark": null,
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }, {
      "id": 2,
      "ticket_type": "ssq",
      "name": "双色球",
      "issue_title": "2024136期 2024-11-26 周二",
      "issue_num": "2024136",
      "red_number": "[03, 11, 15, 21, 25, 26]",
      "blue_number": "[03]",
      "remark": null,
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }, {
      "id": 1,
      "ticket_type": "fc3d",
      "name": "福彩3D",
      "issue_title": "2024318期 2024-11-27 周三",
      "issue_num": "2024318",
      "red_number": "[5, 2, 5]",
      "blue_number": "[]",
      "remark": "试机号：163",
      "create_date": "2024-11-28 15:20:25",
      "update_date": null
  }],
  "timestamp": 1732784934249,
  "executeTime": 5
}