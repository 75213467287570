<template>
  <div class="top-page">
    <van-icon name="arrow-left" style="margin-left: 20px;font-size: 18px;" @click="toPage" />
    <span v-if="title">{{ title }}</span>
    <span v-else>
      <slot></slot>
    </span>
    <van-icon name="arrow-down" style="margin-right: 20px;" />
  </div>
</template>

<script>
export default {
  name: 'TopPage',
  props: {
    title: String,
    goBack: {
      type: String,
      default: () => '/Home'
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    toPage () {
      if (this.goBack) this.$router.push(this.goBack)
      else this.$router.push('/Home')
    }
  }
}
</script>

<style lang="less">
.top-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 40px;
  width: 100%;
  background-color: #65d4d0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 16px;
}
</style>