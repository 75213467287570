<template>
  <div class="box">
    <h3 style="text-align: center;">首页</h3>
	<div class="banner flex">
		<img alt="给生活增添一些小惊喜" src="../assets/banner.png" onclick="return false">
	</div>
    <ul>
      <li v-for="item in list" :key="item.text">
        <router-link :to="item.path"><img :src="item.url" width="30" height="30" class="img_class" />{{ item.text }}</router-link>
      </li>
    </ul>
	<div class="bottom">
		<p class="text">Copyright @2018-2024 开奖结果 版权所有</p>
		<p class="text">成都市隆盛科技有限公司</p>
		<p class="text">川B2-20210083 &nbsp;&nbsp; <a href="https://beian.miit.gov.cn/">蜀ICP备20023670号</a></p>
	 </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
  },
  data() {
    return {
      list: [
        { url: require('../assets/histroy.png'), text: '最新奖表', path: '/AwardList' },
        { url: require('../assets/forecast.png'), text: '预测文章', path: '/ArticleList' },
        { url: require('../assets/logo1.png'), text: '七星彩预测', path: '/ArticleList?ticket_type=qxc' },
		{ url: require('../assets/logo3.png'), text: '排列五预测', path: '/ArticleList?ticket_type=plw' },
        { url: require('../assets/logo2.png'), text: '排列三', path: '/HistoryList?ticket_type=pls' },
        { url: require('../assets/logo4.png'), text: '双色球', path: '/HistoryList?ticket_type=ssq' },
        { url: require('../assets/logo6.png'), text: '超级大乐透', path: '/HistoryList?ticket_type=dlt' },
        { url: require('../assets/logo5.png'), text: '福彩3D', path: '/HistoryList?ticket_type=fc3d' },
      ]
    }
  },
  methods: {
    toPage (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>

<style scoped lang="less">
.box {
  padding: 5px;
  height: 98vh;
  ul {
    background-color: aliceblue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0px !important;
  }
  .banner {
    width: 100%; /* 或者你可以设置为一个固定的最大宽度 */
    max-width: 1200px; /* 根据需要设置最大宽度 */
    height: auto;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
  }
  
  .banner img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  .flex {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      box-sizing: border-box
  }
  ul li {
    list-style: none;
    margin-left: 30px;
    width: 40%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    // border-bottom: 1px solid #ccc;
  }
  .img_class {
    vertical-align: -10px;
    line-height: 50px;
    margin-right: 10px;
  }
}
a {
    text-decoration: none; /* 去除下划线 */
    color: inherit; /* 继承父元素颜色，避免自带的颜色 */
    background-color: transparent; /* 让背景透明，去除默认背景效果 */
}
  .bottom {
      margin: .1rem 0;
  }
  
  .bottom .text {
      color: #bbb;
      font-size: 12px;
      text-align: center;
      margin-bottom: .05rem
  }
</style>
