import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import 'vant/lib/index.css';

import less from 'less';

Vue.config.productionTip = false

Vue.use(less)
Vue.use(Vant);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
