<template>
  <div class="box">
    <TopPage :title="'奖表'"/>
    <div class="box1" v-for="item in winningResultList" :key="item.id" @click="toPrediction(item, 'his')">
      <div class="top-title">{{ item.name }} <span class="top-date">{{ item.issue_title }}</span></div>
      <div class="content">
        <span class="red-class" v-for="(red, index) in item.red_number" :key="red">{{ red }}</span>
        <template v-if="item.blue_number && item.blue_number.length > 0">
          <span class="red-class blue-class" v-for="(blue, index) in item.blue_number" :key="blue">{{ blue }}</span>
        </template>
		<template v-if="item.test_no">
		  <span class="text">试机号：{{ item.test_no }}</span>
		</template>
      </div>
      <div class="bot-cla" v-if="item.ticket_type === 'qxc' || item.ticket_type === 'plw'">
        <van-tag size="large" :text-color="'#000'" :color="'#dfdddd8f'" type="default" @click="toPrediction(item, 'qxc')" v-if="item.ticket_type === 'qxc'" style="margin-right: 10px;">七星彩预测</van-tag>
        <van-tag size="large" :text-color="'#000'" :color="'#dfdddd8f'" type="default" @click="toPrediction(item, 'plw')" v-else style="margin-right: 10px;">排列五预测</van-tag>
        <van-tag size="large" :text-color="'#000'" :color="'#dfdddd8f'" type="default" @click="toPrediction(item, 'his')">历史奖表</van-tag>
      </div>
    </div>
  </div>
</template>

<script>
import TopPage from './TopPage.vue';
import { winningResult } from '../api/index.js'
import { strSplit } from '../utils/strSplit.js'
import {winningResult as winningResults} from '../mock/index.js'
export default {
  name: 'AwardList',
  components: {
    TopPage
  },
  data() {
    return {
      winningResultList: [],
      winningResults
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    strSplit,
    async getList() {
      const res = await winningResult()
      console.log(res, 'res')
      if (res.code === 1 && res.data && res.data.length > 0) {
         this.winningResultList = res.data.map(item => {
            return {
              ...item,
              red_number: this.strSplit(item.red_number).length > 0 && item.red_number !== '[]' ? this.strSplit(item.red_number) : '',
              blue_number: this.strSplit(item.blue_number).length > 0 && item.blue_number !== '[]' ? this.strSplit(item.blue_number) : ''
            }
          })
          console.log(this.winningResultList, 'winningResultList')
      } else {
        this.winningResultList = []
        this.$toast.fail(res.message || '获取数据失败')
      }
    },
    toPrediction (row, type) {
      if (type === 'qxc' || type === 'plw') {
        this.$router.push({ 
          path: '/ArticleList', 
          query: {
            ...row
          }
        })
      }else {
        this.$router.push({ 
          path: '/HistoryList', 
          query: {
            ...row
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
 .box {
  margin-top: 40px;
  background-color: #eeeeeee0;
  .box1 {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    // height: 100px;
    .top-title {
      font-size: 20px;
      color: #000;
    }
    .top-date {
      font-size: 14px;
      color: #999;
    }
	.text {
	    -webkit-box-flex: 1;
	    flex: 1;
	    color: #bbb;
	    font-size: 16px;
	}
    .content {
      height: 50px;
      line-height: 50px;
      .red-class {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        font-weight: bold;
        margin-right: 10px;
      }
      .blue-class {
        display: inline-block;
        background-color: blue;
      }
    }
  }
 }

</style>