<template>
  <div class="award-detail">
    <TopPage :title="title" :go-back="detailId ? '/ArticleList' : '/Home'"/>
    <template v-if="detailId">
      <h3 style="text-align: center;padding-top: 20px;">{{ detailData.title || '' }}</h3>
      <div class="author-class">
        <div class="left">作者：<span class="author">{{ detailData.author || ''}}</span></div>
        <div class="right">发布日期：{{ detailData.publish_date || '' }}</div>
      </div>
      <div v-html="detailData.content" class="content"></div>
    </template>
    <template v-else>
      <van-empty description="暂无内容" />
    </template>
  </div>
</template>

<script>
import TopPage from './TopPage.vue';
import { articleDetail } from '../api/index';
export default {
  name: 'AwardDetail',
  components: {
    TopPage
  },
  data() {
    return {
      title: '七星彩论坛',
      detailId: '',
      detailData: {}
    }
  },
  created() {
    const { id, ticket_type } = this.$route.query;
    this.detailId = id;
    if (!this.detailId) {
      this.title = '详情'
    }else {
      this.title = ticket_type === 'qxc' ? '七星彩论坛' : '排列五论坛';
    }
    this.getDetail(this.detailId)
  },
  methods: {
    async getDetail(id) {
      const res = await articleDetail(id)
      console.log(res, 'rrrrr')
      if (res.code === 1 && res.data) {
        this.detailData = res.data[0] || {}
      }else {
        this.detailData = {}
        this.$toast.fail(res.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.award-detail {
  margin-top: 40px;
  padding: 0 20px;
}
.author-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: rgb(132, 134, 135);
  .author {
    color: #65d4d0;
    text-decoration: underline;
  }
}
</style>