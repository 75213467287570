import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'? 'http://kj.io967ag8.cn:8604/spider/t' : 'http://127.0.0.1:8604/spider/t'
// const baseURL = 'http://171.217.93.89:8604/' || '/';
// 创建Axios实例
const instance = axios.create({
  baseURL: baseURL || '/', // 根据环境变量设置基础URL，若没有环境变量则默认使用根路径
  timeout: 5000 // 设置超时时间，单位为毫秒，这里设置为5秒，可按需调整
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在这里可以添加一些通用的请求头设置，比如添加token等认证信息
    const token = localStorage.getItem('token'); // 假设从本地存储获取token，实际根据项目认证方式获取
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 可以在这里对响应数据进行统一处理，比如判断状态码是否正常等
    const { data } = response;
    if (data.code === 1) {
      return data;
    } else {
      // 若状态码不正常，可以根据项目需求进行相应处理，比如抛出错误提示等
      return Promise.reject(data);
    }
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;