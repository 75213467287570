export const strSplit = (str) => {
  str = str.slice(1, -1);
  let arr = str.split(',');
  arr = arr.map(item => parseInt(item, 10));
  return arr;
}

export const textSplit = (text, type) => {
  let leftPart = text;
  let middlePart = "";
  let rightPart = "";
  let openIndex = text.indexOf("(");
  let closeIndex = text.indexOf(")");
  if (openIndex!== -1 && closeIndex!== -1 && closeIndex > openIndex) {
    middlePart = text.slice(openIndex, closeIndex + 1);
    leftPart = text.slice(0, openIndex).trim();
    rightPart = text.slice(closeIndex + 1).trim();
  }
  if (type === 'left') {
    return leftPart;
  }else if (type === 'middle') {
    return middlePart;
  }else if (type === 'right') {
    return rightPart;
  }
}