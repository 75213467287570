<template>
  <div>
    <TopPage :title="title" :go-back="title ? '/AwardList' : '/Home'"/>
    <div class="history-list">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getHistoryList"
          :offset="100"
        >
          <van-cell style="border-bottom: 1px solid #ebedf0;height: 100px;" v-for="(item) in allHistoryList" :key="item.id">
            <template v-if="item.indexType === 'first'">
              <div class="box1">
                <div class="top-title">{{ item.issue_num ? `${item.issue_num}期` : '' }} <span class="top-date">{{ item.issue_title }}</span></div>
                  <div class="content">
                    <span class="red-class" v-for="(red) in item.red_number" :key="red">{{ red }}</span>
                    <template v-if="item.blue_number && item.blue_number.length > 0">
                      <span class="red-class blue-class" v-for="(blue) in item.blue_number" :key="blue">{{ blue }}</span>
                    </template>
                </div>
              </div>
            </template>
            <template v-else-if="!item.indexType">
              <div class="box1">
                <div class="top-title1">{{ item.issue_num ? `${item.issue_num}期` : '' }} <span class="top-date">{{ item.issue_title }}</span></div>
                  <div class="content" style="margin-left: 10px;">
                    <span class="red-class1" v-for="(red) in item.red_number" :key="red">{{ red }}</span>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </div>
  </div>
</template>

<script>
import TopPage from './TopPage.vue';
import { strSplit } from '../utils/strSplit.js'
import { winningList } from '../api/index.js'
export default {
  name: 'HistoryList',
  components: {
    TopPage
  },
  data() {
    return {
      title: '七星彩奖表',
      loading: false,
      finished: false,
      pageObj: {
        page: 1,
        size: 10
      },
      ticket_type: 'qxc',
      historyList: [],
      allHistoryList: []
    }
  },
  created() {
    const query = this.$route.query
    this.title = query.name || '历史奖表'
	this.ticket_type = query.ticket_type
    // this.getHistoryList()
  },
  methods: {
    strSplit,
    async getHistoryList() {
      let params = {
        ...this.pageObj,
        ticket_type: this.ticket_type
      }
      const res = await winningList(params)
      if (res.code === 1 && res.data.list.length > 0) {
        this.historyList = res.data.list.map(item => {
          return {
              ...item,
              red_number: item.red_number !== '[]' ? this.strSplit(item.red_number) : '',
              blue_number: item.blue_number !== '[]' ? this.strSplit(item.blue_number) : ''
            }
        })
        if (this.pageObj.page === 1) {
          this.allHistoryList = this.historyList
        }else {
          this.allHistoryList = [...this.allHistoryList, ...this.historyList]
        }
        this.allHistoryList = this.allHistoryList.map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              indexType: 'first'
            }
          }else {
            return item
          }
        })
        if (this.allHistoryList.length >= res.data.total) {
          this.finished = true;
          this.$toast.success('已加载全部数据')
        }else {
          this.pageObj.page++
        }
        this.loading = false
      }else {
        this.loading = false
        this.$toast.fail(res.message || '获取数据失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.history-list {
  margin-top: 40px;
}
.box1 {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 10px;
    height: 70px;
    .top-title {
      font-size: 18px;
      color: #000;
      font-weight: bold;
    }
    .top-date {
      font-size: 14px;
      color: #999;
      margin-left: 10px;
    }
    .content {
      height: 50px;
      line-height: 50px;
      .red-class {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        font-weight: bold;
        margin-right: 10px;
      }
      .blue-class {
        display: inline-block;
        background-color: blue;
      }
    }
    .top-title1 {
      font-size: 18px;
      color: #999;
    }
    .red-class1 {
      color: red;
      font-weight: bold;
      font-size: 16px;
      margin-right: 20px;
    }
}
</style>