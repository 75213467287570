import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/components/Home.vue';
import ArticleList from '@/components/ArticleList.vue';
import AwardDetail from '@/components/AwardDetail.vue';
import AwardList from '@/components/AwardList.vue';
import HistoryList from '@/components/HistoryList.vue';

Vue.use(VueRouter);

   const routes = [
        { path: '/', name: 'HomePage', component: HomePage },
        { path: '/Home', name: 'HomePage', component: HomePage },
        { path: '/ArticleList', name: 'ArticleList', component: ArticleList },
        { path: '/AwardDetail', name: 'AwardDetail', component: AwardDetail },
        { path: '/AwardList', name: 'AwardList', component: AwardList },
        { path: '/HistoryList', name: 'HistoryList', component: HistoryList },
      ]


const router = new VueRouter({
  mode: "history",
  routes
});
export default router;
