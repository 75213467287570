import axios from '@/utils/axios' 

//获取最新开奖列表
export function winningResult() {
  return axios({
   method:'GEt',
   url:'/winning/result'
  })
}

//获取历史开奖列表
export function winningList(data) {
  return axios({
   method:'GEt',
   url: `/winning/list?page=${data.page}&size=${data.size}&ticket_type=${data.ticket_type}`,
  })
}
//获取七星彩列表
export function articleList(data) {
    return axios({
     method:'GEt',
     url: `/article/list?page=${data.page}&size=${data.size}&ticket_type=${data.ticket_type}`,
    })
  }
//获取预测文章详情
export function articleDetail(id) {
    return axios({
     method:'GEt',
     url: `/article/detail/${id}`,
    })
  }

